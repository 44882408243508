import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useNavigate } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import ListaVagas from "components/blogs/ListaVagas";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import ListaCurriculos from "components/blogs/listaCurriculosSearch";
import CadastroVaga from "components/forms/CadastroVaga";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw` flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;
const SubmitButton = tw.button`w-full text-center sm:w-64 py-3 bg-gray-100 text-gray-700 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-gray-700 hocus:-translate-y-px hocus:shadow-xl`;
const CadastroVagaBtn = tw.div`w-full text-center pb-3`;

export default ({
 
  linkText = "Continuar",
  heading = "Gerenciar Vagas",
  subheading = "Vagas",
  description = "Gerencie e Adicione vagas",
  imageContainerCss = null,
  imageCss = null, 
  setCurriculoAtual, 
  user
}) => {
  const [cadastroVaga, setCadastroVaga] = useState(false);
  const [listaVagas, setListaVagas] = useState(false);
  /*
  const cards =  [
    {
      imageSrc: ShieldIconImage,
      title: "Cadastrar Vaga",
      description: "Cadastre uma nova vaga",
      url: "/curriculos",
      click: ()=>{setCadastroVaga(true)}
    },
    {
      imageSrc: SupportIconImage,
      title: "Gerencie as Vagas",
      description: "Gerencie as vagas já cadastradas",
      url: "/alterarCurriculo",
      click: ()=>{setListaVagas(true)}
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Cadastrar currículo",
      description: "Cadastrar um novo Currículo",
      url: "/apagarCurriculo",
      click: ()=>{alert("hi3")}
    }
  ]*/

    const navigate = useNavigate();
    
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container tw="pb-32 min-h-screen80">
      
        
        <ThreeColumnContainer>
          {/*cards.map((card, i) => (
            <Column key={i}>
              <Card onClick={()=>{card.click()}}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
                ))*/}
        </ThreeColumnContainer>
      
      <ListaVagas setListaVagas={setListaVagas} cadastroVaga={cadastroVaga} />
      <CadastroVagaBtn><SubmitButton type="button" value="Submit" onClick={()=>{setCadastroVaga(true)}}>Cadastrar Vaga</SubmitButton></CadastroVagaBtn>
      {cadastroVaga && <CadastroVaga setCadastroVaga={setCadastroVaga} />}
      
      
    </Container>
    

  );
};
