// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDF2bN0LskRK-Zk-Dz1TuNA9A1-M3yOstY",
  authDomain: "vulcano-31f2f.firebaseapp.com",
  projectId: "vulcano-31f2f",
  storageBucket: "vulcano-31f2f.appspot.com",
  messagingSenderId: "894861363263",
  appId: "1:894861363263:web:a49289fdfc18e21089908a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)