import React, { useEffect, useState, props } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { db } from "services/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
import ModalDescandidatar from "components/features/ModalDescandidatar";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;
const Row = styled.div`
${tw`flex flex-col items-center  lg:items-stretch overflow-hidden lg:flex-row mt-1 bg-gray-700 transition duration-200 hover:bg-gray-800  flex-wrap`}
  div{
    ${tw`p-2 border-r-white text-white md:border-r-2 `}
  }
  .colunaNome{
    ${tw`lg:w-1/3 pl-5 `}
  }
  .colunaFuncao{
    ${tw`lg:w-1/6 `}
  }
  .colunaIcon{
    ${tw`lg:w-2/12 font-bold md:font-normal cursor-pointer `}
  }
  
`;
const RowTitle = styled.div`
${tw`md:flex hidden flex-col items-center  lg:items-stretch overflow-hidden lg:flex-row mt-1 bg-gray-800 font-light   flex-wrap`}
  div{
    ${tw`p-2 border-r-white text-white border-r-2 `}
  }
  .colunaNome{
    ${tw`lg:w-1/3 pl-5 `}
  }
  .colunaFuncao{
    ${tw`lg:w-1/6 `}
  }
  
  
  
`;
const TitleSm = tw.span`md:hidden`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug text-secondary-500 font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Vagas",
  heading = <>Vagas <span tw="text-secondary-500">Candidatadas</span></>,
  description = "Some amazing blog posts that are written by even more amazing people.",
  curriculoAtual,
  user,
  setCurriculoAtual
  

}) => {
  console.log(user)
  const [MVaga, setMVaga] = useState("");
  const [vagaUid, setVagaUid] = useState("")
  
  const [nome, setNome] = useState("")
    const navigate = useNavigate();
    const [curriculos, setCurriculos] = useState([]);
    const vagasCollectionRef = collection(db, "vagas")

    useEffect(() => {
      if(user){
        if(user.vagasC.length > 0){
           const q = query(vagasCollectionRef, where("vagaUid", "in", user.vagasC))
            const getCurriculos = async () => {
            const data = await getDocs(q)
            
            setCurriculos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        };getCurriculos()
        } 
        

        
        }
    }, [user])
    useEffect(()=> {
        console.log(curriculos)
    }, [curriculos])
  
  return (
    
    <>
    {user && (<>
    {MVaga && <ModalDescandidatar nome={nome} user={user} vagaUid={vagaUid} setMVaga={setMVaga}></ModalDescandidatar>}
    <Container>
     
      <Content>
      <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          
        </HeadingInfoContainer>
        {curriculos.length > 0 ? (<>
        <RowTitle >
    <div className="colunaNome">Vaga</div>
    <div className="colunaFuncao">N. de vagas</div>
    <div className="colunaFuncao">Atuação</div>
    
    <div className="colunaNome">Ações</div>
    
   </RowTitle>
          {curriculos.map((curri, index) => (
            <Row key={index}>
          <div className="colunaNome"><TitleSm>Nome: </TitleSm>{curri.nome}</div>
          <div className="colunaFuncao"><TitleSm>N.º de vagas: </TitleSm>{curri.numero}</div>
          <div className="colunaFuncao"><TitleSm>Atuação: </TitleSm>{curri.atuacao}</div>
       
          <div className="colunaIcon" onClick={(e) => {
                    e.preventDefault()                   
                    navigate(`/verVagaUser/${curri.vagaUid}/1/${user.uid}`)
                  }}>Detalhes</div>
          <div className="colunaIcon" onClick={()=>{setMVaga(true);setVagaUid(curri.vagaUid);setNome(curri.nome)}} >Descandidatar </div>
          
         </Row>
            
          ))}</>):(<div tw="text-center mt-2"><span tw="text-center font-bold">Ainda não há vagas candidatadas</span></div>)}
       
       
      </Content>
      
    </Container></>)}</>
  );
};
