import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useNavigate } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import CadastroAcademica from "components/forms/CadastroAcademica";
import CadastroExperiencia from "components/forms/CadastroExperiencia";
import ModalDeleteAcademica from "./ModalDeleteAcademica";
import CadastroHabilidades from "components/forms/CadastroHabilidades";
import CadastroCursos from "components/forms/CadastroCursos";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-full bg-white mb-10`}
`;

const Card = styled.a`
  ${tw`flex flex-col  text-left h-full mx-4 px-4 py-8 rounded transition-transform duration-300 `}
  .imageContainer {
    ${tw`text-left rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }
  .dadosC {
    ${tw`mt-4 text-sm font-light text-gray-600`}
  }
  .acao {
    ${tw`underline cursor-pointer`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 cursor-pointer leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
 
  linkText = "Continuar",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null, 
  setCurriculoAtual, 
  user
}) => {
  console.log(user);
  const academicaCollectionRef = collection(db, "curriculos", user.id, "academica");
  const habilidadesCollectionRef = collection(db, "curriculos", user.id, "habilidades");


    const navigate = useNavigate();
    const [mDelete, setMDelete] = useState(false);
    const [deleteNome, setDeleteNome] = useState("");
    const [deleteTipo, setDeleteTipo] = useState("");
    const [deleteUid, setDeleteUid] = useState();
    const experienciasCollectionRef = collection(db, "curriculos", user.id, "experiencias");
    const cursosCollectionRef = collection(db, "curriculos", user.id, "cursos");

    const [experiencias, setExperiencias] = useState([]);
    const [habilidades, setHabilidades] = useState([]);
    const [curso, setCurso] = useState("")
    const [escola, setEscola] = useState("")
    const [empresa, setEmpresa] = useState("")
    const [cargo, setCargo] = useState("")



    const qAcademica = query(academicaCollectionRef);
    const qHabilidades = query(habilidadesCollectionRef);
    const qCursos = query(cursosCollectionRef);


    const [cadastrado, setCadastrado] = useState(true);
    const [academica, setAcademica] = useState([]);
    const [cursos, setCursos] = useState([]);

const [cadastroFormacao, setCadastroFromacao] = useState(false)
const [cadastroCursos, setCadastroCursos] = useState(false)

    const [cadastroExperiencia, setCadastroExperiencia] = useState(false)
    const [cadastroHabilidades, setCadastroHabilidades] = useState(false)
    
    useEffect(()=>{
    const getAcademica = async () => {
                const data = await getDocs(qAcademica)
                console.log(data);
                setAcademica(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
                
          }
          const getHabilidades = async () => {
            const data = await getDocs(qHabilidades)
            console.log(data);
            setHabilidades(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
      }
      const getCursos = async () => {
        const data = await getDocs(qCursos)
        console.log(data);
        setCursos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
        
  }

          
          const qExperiencias = query(experienciasCollectionRef);
        const getExperiencias = async () => {
            const data = await getDocs(qExperiencias)
            console.log(data);
            setExperiencias(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
          getAcademica()
          getExperiencias()
          getHabilidades()
          getCursos()
    },[cadastrado])
    
    
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <>
    {mDelete && <ModalDeleteAcademica nome={deleteNome} userUid={user.uid} curso={curso} cargo={cargo} empresa={empresa} escola={escola} uid={deleteUid} setMDelete={setMDelete} tipo={deleteTipo}></ModalDeleteAcademica>}
    {!cadastroExperiencia && !cadastroFormacao && !cadastroCursos && !cadastroHabilidades ? ( <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          
            <Column >
              <Card onClick={()=> {}}>
               
                <span className="title">Dados Pessoais</span>
                <p className="description">Confira os dados de seu Currículo</p>
                <p className="dadosC">Nome: {user.nome}<br></br>
                                      Email:{user.email}<br></br>
                                      CPF: {user.cpf}</p>
                
                  <span onClick={()=> {navigate(`/verCurriculo/${user.uid}`)}} className="link">
                    <span>Ver Currículo</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                  <span onClick={()=> {navigate(`/alterarCurriculo/${user.uid}`)}} className="link">
                    <span >Alterar Currículo</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                
              </Card>
            </Column>
            <Column >
              <Card onClick={()=> {}}>
               
                <span className="title">Formações Acadêmicas</span>
                {academica.length == 0 ? (<>
                  <p className="description">Não há formações acadêmicas cadastradas</p>
                </>):(<><p className="description">Formações Acadêmicas</p>
                {academica.map((academica, index) => (
                <p className="dadosC">Curso: {academica.curso}<br></br>
                                      Escola: {academica.escola}<br></br>
                                      <span className="acao" onClick={()=>{setMDelete(true);setCurso(academica.curso);setEscola(academica.escola);setDeleteTipo("academica");setDeleteUid(academica.academicaUid);setDeleteNome(academica.curso)}}>Apagar</span> 
                                     </p>))}</>)}
                
                
                  {academica.length < 3 && <span tw="cursor-pointer" onClick={()=>{setCadastroFromacao(true)}} className="link">
                    <span>Adicionar formação Acadêmicas</span>
                    <ArrowRightIcon className="icon" />
                  </span>}
                  
                
              </Card>
            </Column>
            

            <Column >
              <Card onClick={()=> {}}>
               
                <span className="title">Experiências Profissionais</span>
                {experiencias.length == 0 ? (<>
                  <p className="description">Não há experiências profissionais cadastradas</p>
                </>):(<><p className="description">Experiências Profissionais</p>
                {experiencias.map((academica, index) => (
                <p className="dadosC">Empresa: {academica.empresa}<br></br>
                                      Cargo: {academica.cargo}<br></br>
                                      <span className="acao" onClick={()=>{setMDelete(true);setDeleteTipo("experiencias");setCargo(academica.cargo);setEmpresa(academica.empresa);setDeleteUid(academica.experienciaUid);setDeleteNome(academica.cargo + " - " + academica.empresa)}}>Apagar</span> 
                                     </p>))}</>)}
                
                
                  {experiencias.length < 3 && <span tw="cursor-pointer" onClick={()=>{setCadastroExperiencia(true)}} className="link">
                    <span>Adicionar Experiência Profissional</span>
                    <ArrowRightIcon className="icon" />
                  </span>}
                  
                
              </Card>
            </Column>

            <Column >
              <Card onClick={()=> {}}>
               
                <span className="title">Habilidades e Conhecimentos</span>
                {habilidades.length == 0 ? (<>
                  <p className="description">Não há habilidades e conhecimentos cadastrados</p>
                </>):(<><p className="description">Habilidades e Conhecimenntos</p>
                {habilidades.map((academica, index) => (
                <p className="dadosC">
                                      {academica.habilidades}<br></br>
                                      <span className="acao" onClick={()=>{setMDelete(true);setDeleteTipo("habilidades");setCargo(academica.cargo);setEmpresa(academica.empresa);setDeleteUid(academica.habilidadesUid);setDeleteNome("essas habilidades e conhecimentos")}}>Apagar</span> 
                                     </p>))}</>)}
                
                
                  {habilidades.length < 3 && <span tw="cursor-pointer" onClick={()=>{setCadastroHabilidades(true)}} className="link">
                    <span>Adicionar Habilidades e Conhecimentos</span>
                    <ArrowRightIcon className="icon" />
                  </span>}
                  
                
              </Card>
            </Column>

            <Column >
              <Card onClick={()=> {}}>
               
                <span className="title">Cursos e Certificações</span>
                {cursos.length == 0 ? (<>
                  <p className="description">Não há cursos ou certificações cadastrados</p>
                </>):(<><p className="description">Cursos e certificações</p>
                {cursos.map((academica, index) => (
                <p className="dadosC">
                                      Título: {academica.titulo}<br></br>
                                      Instituicao: {academica.instituicao}<br></br>
                                      <span className="acao" onClick={()=>{setMDelete(true);setDeleteTipo("cursos");setCargo(academica.cargo);setEmpresa(academica.empresa);setDeleteUid(academica.cursoUid);setDeleteNome(academica.titulo)}}>Apagar</span> 
                                     </p>))}</>)}
                
                
                  {cursos.length < 3 && <span tw="cursor-pointer" onClick={()=>{setCadastroCursos(true)}} className="link">
                    <span>Adicionar Cursos ou Certificações</span>
                    <ArrowRightIcon className="icon" />
                  </span>}
                  
                
              </Card>
            </Column>
            
         
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      
    </Container>):(<></>)}
    {cadastroExperiencia && <CadastroExperiencia userUid={user.uid} setCadastrado2={setCadastrado} setCadastroExperiencia={setCadastroExperiencia}></CadastroExperiencia>}
    {cadastroCursos && <CadastroCursos userUid={user.uid} setCadastrado2={setCadastrado} setCadastroCursos={setCadastroCursos}></CadastroCursos>}

    {cadastroHabilidades && <CadastroHabilidades userUid={user.uid} setCadastrado2={setCadastrado} setCadastroExperiencia={setCadastroHabilidades}></CadastroHabilidades>}
      {cadastroFormacao && <CadastroAcademica userUid={user.uid} setCadastrado2={setCadastrado} setCadastroFromacao={setCadastroFromacao}></CadastroAcademica>}</>
  );
};
