import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useNavigate } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { doc, deleteDoc } from "firebase/firestore";


const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ModalWrapper = styled.div`
  ${tw`w-full h-full bg-black fixed top-0 left-0 opacity-75 z-40`}
`;
const ModalContent = styled.div`
  ${tw`w-full max-h-full overflow-scroll p-10`}
`;
const Modal = styled.div`
  ${tw`w-full h-full rounded-2xl overflow-hidden  bg-gray-200  inset-1/2 opacity-100  p-10 text-gray-600 text-center`}
  span{
    ${tw`text-black   text-justify  leading-6 block`}
  }
  h2{
    ${tw`text-black   mb-10  leading-6 block`}
  }
`;
const ModalWrapper2 = styled.div`
  ${tw` w-full max-w-block h-screen rounded-2xl    fixed inset-1/2 opacity-100 z-50 -translate-x-1/2 -translate-y-1/2 py-20 text-gray-600 text-center`}
  
`;

const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;
const SubmitButton = tw.button`w-full mt-4 text-primary-600 sm:w-32 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const SubmitButton2 = tw.button`w-full mt-4 ml-2 text-primary-600 sm:w-32 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;




const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;


export default ({
    setModal
    
 
}) => {
  const navigate =  useNavigate()
  const [deletado, setDeletado] = useState(false);
  
  const action = ()=>{
    
    setModal(false)
  
  }

    
    
    
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <>
    <ModalWrapper>
        
    </ModalWrapper>
    <ModalWrapper2>
       
    <Modal><ModalContent><h2>Termos de Serviço</h2><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis auctor at leo eget molestie. Fusce arcu nibh, rhoncus sit amet mollis in, pulvinar et nunc. Suspendisse imperdiet massa ullamcorper mi sagittis dictum. Proin a sodales erat. Morbi in commodo ex. Morbi rutrum euismod aliquet. Nam odio erat, blandit hendrerit porttitor eu, luctus eget mi. Pellentesque nec justo id enim eleifend dignissim. Fusce suscipit mattis est, ut consequat risus porttitor quis. Integer mollis consectetur odio, in bibendum tortor laoreet nec. Proin sit amet ex sed purus ultrices interdum eu luctus augue. Nulla cursus efficitur tempor. Quisque malesuada enim sem, ut aliquet quam consectetur vitae.

Integer blandit semper dui a maximus. Ut dictum bibendum tortor, a suscipit magna placerat at. Curabitur placerat tellus et dictum ultricies. Morbi ornare lobortis condimentum. Suspendisse ornare pretium felis a pellentesque. Ut tincidunt libero eget augue condimentum pretium. Donec tristique pellentesque pharetra. Quisque hendrerit, nisl vel ullamcorper mattis, sapien quam blandit justo, eget dignissim mi nibh sit amet metus. Aliquam erat volutpat. Suspendisse sit amet suscipit purus, vel varius quam. Phasellus eget scelerisque justo.

Donec dapibus quam eget mauris euismod finibus eget in eros. Donec sed pharetra enim. Donec mattis nibh quis diam placerat, ut ultricies nisl mattis. Sed rhoncus orci tortor, non tincidunt enim lobortis a. Maecenas lacinia sagittis consequat. Praesent vel ante nec urna faucibus lobortis sed at elit. Duis luctus ac elit vitae dictum. Phasellus cursus, tortor et accumsan tempus, risus lectus pellentesque elit, eu luctus risus risus vel sem.

Fusce at velit laoreet, suscipit nulla quis, varius nisl. Praesent lacinia, magna varius faucibus molestie, nisl tortor sodales ex, nec faucibus velit turpis sit amet nibh. Nulla sodales lectus sed purus elementum, a consequat elit suscipit. Maecenas et turpis erat. Phasellus bibendum felis quis metus lobortis, vel molestie elit dignissim. Nulla ac velit sed orci malesuada malesuada ac sit amet nunc. Etiam molestie porttitor odio sed malesuada. Nulla dignissim ac eros at ultrices. Aliquam vulputate sem sit amet blandit finibus. Duis eu arcu sit amet nisl lobortis porttitor. Nulla mattis faucibus nisi in lobortis. Mauris in interdum leo.

Donec non justo ac urna pretium gravida. Curabitur blandit tempor massa, rutrum pulvinar augue scelerisque quis. Sed nunc felis, fermentum ac fringilla eget, ultricies sit amet nisi. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent vestibulum mollis libero euismod dictum. Aliquam eu quam consequat, viverra quam sed, fringilla urna. Praesent efficitur pretium mauris, ac egestas arcu lacinia nec. Vivamus sed tincidunt sapien. Fusce a quam imperdiet, vulputate velit id, luctus justo. Duis at tincidunt quam. Phasellus pretium tempus sapien non faucibus. Maecenas in malesuada metus, ac tempus urna. Nam fermentum augue arcu, a volutpat elit feugiat eget. Aliquam ut mollis metus. Sed vel bibendum augue.

Quisque condimentum diam sem. Pellentesque et egestas est. Integer non eros finibus, lacinia est eu, tristique ante. Nullam at finibus nibh. Maecenas viverra sapien sem, ac sollicitudin nulla vulputate eu. Nunc lacus quam, pretium at cursus mollis, volutpat eu ex. Vivamus in dictum nibh. Integer a purus hendrerit, ultrices lacus non, pellentesque nisl. Nulla tincidunt mi non justo molestie, nec tristique velit volutpat. Quisque non nibh et mi elementum imperdiet. Etiam tellus lectus, maximus eu finibus nec, pulvinar aliquam odio. Etiam eget nulla aliquet, malesuada orci vitae, laoreet mauris. Vestibulum convallis purus tortor, ac maximus odio ullamcorper efficitur. Ut malesuada viverra est eget luctus. Duis eu metus neque.

Maecenas sagittis et velit nec tempor. Nulla facilisi. Morbi pulvinar quis metus non venenatis. Praesent rhoncus ex ac dolor maximus, vitae vehicula nibh placerat. In faucibus sem eget egestas dictum. Praesent facilisis elit eu lacus pulvinar, eu malesuada turpis commodo. Sed at sodales quam.

Curabitur volutpat efficitur augue, vitae sagittis metus sodales eu. Nam bibendum sem eget justo sodales, ac tincidunt enim tempor. Etiam dignissim metus vitae aliquam finibus. Donec sed ante eget diam sodales pulvinar vitae ac massa. Sed placerat risus risus, ac venenatis ipsum tristique et. Vivamus sit amet lacus porta, porta tortor ut, bibendum nunc. Proin eget ornare leo.

Cras egestas euismod semper. Nulla facilisi. Phasellus quis lacus ut massa porttitor consectetur. In molestie sapien ut ullamcorper pretium. Phasellus at odio odio. Morbi lobortis sollicitudin lacus sit amet ullamcorper. Morbi gravida justo ac auctor mattis. Phasellus nibh augue, consectetur sed porta et, luctus id massa. Sed tristique, justo vel feugiat tincidunt, nibh erat cursus sapien, a aliquam arcu sapien vitae velit. Phasellus tristique, arcu eu aliquet auctor, odio lectus accumsan nunc, id fermentum eros turpis at neque. Suspendisse potenti. Ut ullamcorper sollicitudin vulputate. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.

Pellentesque in nibh a augue posuere gravida nec faucibus turpis. Mauris pretium hendrerit sapien, nec luctus tellus. Aenean mollis elit non est cursus semper. Nulla facilisi. Etiam tellus massa, facilisis eget viverra eu, efficitur vel magna. Nulla vel tellus non arcu cursus tincidunt ac ut ligula. Nam tincidunt vel mauris non interdum. Curabitur tempor rhoncus sagittis. Maecenas in magna massa. Nulla aliquet viverra diam a auctor. Nullam nec dignissim augue, sed dapibus lectus. Phasellus sed lacinia tortor, vel ornare ex.

Nullam imperdiet ligula at arcu vulputate molestie. Sed porttitor sem vel massa scelerisque, at pellentesque quam posuere. Nullam sit amet facilisis risus, nec eleifend odio. Integer quis placerat nibh. Nulla tincidunt feugiat est. Nam ullamcorper, nulla nec dictum venenatis, tortor lacus viverra neque, non semper enim tellus sit amet purus. Nulla porta leo eget ligula interdum congue. In hac habitasse platea dictumst. Fusce tempus neque in purus feugiat, ut efficitur purus dapibus. Aenean tincidunt ligula in sapien semper, id dictum felis dapibus. </span>
       
        <SubmitButton2 type="button" onClick={()=>{action()}} >Fechar</SubmitButton2></ModalContent>
        </Modal></ModalWrapper2></>
  );
};
