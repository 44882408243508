import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { auth, db} from "services/firebaseConfig.js";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import { useForm } from "react-hook-form";
import { setDoc, collection, doc } from "firebase/firestore";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/slide2.jpg";
import logo from "images/logo.png";
import ModalTermos from "components/features/ModalTermos";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-20 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-sm xl:text-sm font-light text-center`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const Errors = tw.span`text-gray-900 text-xs inline-block`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;



const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-light bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-orange-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Seja Bem vindo a Plataforma de Recursos Humanos da Metalúrgica Vulcano!",

  submitButtonText = "Cadastre-se",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#"
}) => {
  const validation = Yup.object().shape({
    chooseCB: Yup.bool().oneOf([true], 'Por favor, concorde com os termos'),
  })
    const navigate = useNavigate();
    const optionsForm = { resolver: yupResolver(validation) }
    const usersColectionef = collection(db, "users");
    const { register, handleSubmit, reset, formState } = useForm(optionsForm);
    const { errors } = formState
    const [erro, setErro] = useState("");
    const [modal, setModal] = useState(false);
    const [dataCheck, setDataCheck] = useState("")
    const [horaCheck, setHoraCheck] = useState("")
    const [nome, setNome] = useState("");



    const onSubmit = data => {
      createUserWithEmailAndPassword(data.email, data.password)
      setNome(data.nome)
      return false;
      
    };
    const createUser = async (user) => {
      await setDoc(doc(db, "users", user.user.uid), {
        email: user.user.email,
        nome: nome,
        tipo: "usuario",
        curriculo: false,
        dataCheck: dataCheck,
        horaCheck: horaCheck,
        uid: user.user.uid
      });
      navigate(`../home/`)
    }
    const [
      createUserWithEmailAndPassword,
      user,
      loading,
      error,
    ] = useCreateUserWithEmailAndPassword(auth);


    useEffect(() => {

      if (error) {
        if(error.message == "Firebase: Error (auth/invalid-email).")
        setErro("Insira um e-mail válido")
        else if(error.message == "Firebase: Error (auth/email-already-in-use).")
        setErro("E-mail já cadastrado")
        else if(error.message == "Firebase: Password should be at least 6 characters (auth/weak-password).")
        setErro("A senha deve possuir no mínimo 6 caracteres")
        else
      setErro(error.message);
     
    }
      if (user) {


      createUser(user);
    }
    }, [error] [user])
    

    return(
      <>
      {modal &&<ModalTermos setModal={setModal}></ModalTermos>}
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink href={logoLinkUrl}>
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
              
             
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input {...register("nome")} type="text" placeholder="Nome" />
                <Input {...register("email")} type="email" placeholder="Email" />
                <Input {...register("password")} type="password" placeholder="Password" />
                
                <input {...register("chooseCB", {
                  onChange: ()=>{
                    const data = new Date()
                    const dia = String(data.getDate()).padStart(2, '0')
                    const mes = String(data.getMonth() + 1).padStart(2, '0')
                    const ano = String(data.getFullYear())
                    const horas = String(data.getHours()).padStart(2, '0')
                    const minutos = String(data.getMinutes()).padStart(2, '0')
                    const segundos = String(data.getSeconds()).padStart(2, '0')

                    setDataCheck(`${dia}/${mes}/${ano}`)
                    setHoraCheck(`${horas}:${minutos}:${segundos}`)
                    
                  }
                })} type="checkbox" tw="mt-3" />
                <label tw="mt-10 ml-2 text-xs text-gray-600 text-center">
                    Concordo com os {" "}
                  <a href="#" onClick={()=>{setModal(true)}} tw="border-b border-gray-500 border-dotted">
                    Termos de serviço
                  </a>{" "}
                </label>
                <Errors>{errors?.chooseCB?.message}</Errors>
                <SubmitButton type="submit">
                  <SubmitButtonIcon className="icon" />
                  <span className="text">Cadastrar meu  currículo</span>
                </SubmitButton>
                <p tw="mt-3 text-xs text-red-600 text-center">{erro}</p>
                

                
                  <Link to="/">
                  <SubmitButton type="button">
                  
                  <span className="text">Já tenho cadastro</span>
                </SubmitButton>
                  </Link>
                
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer style={{backgroundImage: `url(${illustration})`,
                                        backgroundSize: `cover`,
                                        backgroundPosition: `center`}}>
          
        </IllustrationContainer>
      </Content>
    </Container>
  </AnimationRevealPage>
  </>
)};
