import React, { useState, props, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useNavigate,  useParams } from "react-router-dom";
import light from "components/headers/light";
import LightFuncionaario from "components/headers/lightFuncionaario";
import { useForm } from "react-hook-form";
import { collection, addDoc, setDoc, doc, query, where, getDocs } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 mt-20 sm:p-12 md:p-16  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-2xl font-light`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 text-white hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-64 mt-6 mr-10 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-secondary-500 fill-current w-24`

export default () => {
  const {id} = useParams();
 
    const navigate = useNavigate();
    
    const [vagas, setVagas] = useState([]);
    const vagasCollectionRef = collection(db, "vagas");
    const q = query(vagasCollectionRef, where("vagaUid", "==", String(id)));
    useEffect(() => {
        const getVagas = async () => {
            const data = await getDocs(q)
            console.log(data);
            setVagas(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getVagas()
    }, [])
    useEffect(()=> {
        
        console.log(vagas)
    }, [vagas])


  const { register, handleSubmit } = useForm();
  const [cadastrado, setCadastrado] = useState(false);
  const vagasCollection = collection(db, "vagas")
  const usersCollection = collection(db, "users")
  const [parte2, setParte2] = useState(false)
  const onSubmit = async (data) => {
    await setDoc(doc(vagasCollection, id), {
        vagaUid: id,
        nome: data.nome,
        numero: data.numero,
        atuacao: data.atuacao,
        descricao: data.descricao
    }, {merge: true})
    
    setCadastrado(true);
  }
  return (
    <>
    <LightFuncionaario></LightFuncionaario>
    <Container>
      <Content>
      {vagas.map((curri, index) => (
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer tw="bg-gray-700">
          <div tw="mx-auto max-w-5xl">
          <h2 className="formTitle">Alterar Vaga</h2>

            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Nome da Vaga</Label>
                    <Input {...register("nome", {value:curri.nome})} disabled={cadastrado} id="name-input" type="text" name="nome" placeholder="Nome da vaga" />
                  </InputContainer>
                  </Column>
                  <Column>
                  <InputContainer>
                    <Label htmlFor="email-input">Área de Atuação</Label>
                    <Input {...register("atuacao", {value:curri.atuacao})} disabled={cadastrado} id="email-input" type="text" name="atuacao" placeholder="Área de atuação" />
                  </InputContainer>
                  </Column><Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Número de vagas</Label>
                    <Input {...register("numero", {value:curri.numero})} disabled={cadastrado} id="name-input" type="text" name="numero" placeholder="Número de vagas" />
                  </InputContainer>
                
                  <InputContainer>
                    <Label htmlFor="name-input">Descrição da vaga</Label>
                    <TextArea id="message-input" disabled={cadastrado} {...register("descricao", {value:curri.descricao})} type="text" name="descricao" placeholder="Descrição da vaga" />
                  </InputContainer>
                  
                </Column>
               
                
              </TwoColumn>
              {!cadastrado && (<>
              <SubmitButton tw="text-secondary-500" type="submit"  value="Submit">Alterar Vaga</SubmitButton>
              <SubmitButton tw="text-secondary-500" type="button" onClick={()=>{navigate("../vagas/")}}  value="Submit">Voltar</SubmitButton>
              </>)}
              
              {cadastrado && <>
              <p><br></br>Alterado com sucesso!</p>
              <SubmitButton tw="text-secondary-500" type="submit" onClick={()=>{navigate("../vagas/")}}  value="Submit">Ok</SubmitButton>
              </>}
            
          </div>
          <SvgDotPattern1 />
        </FormContainer>



       
          
        </form>
      ))}
      </Content>
    </Container></>
  );
};
