import React, { useState, props, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { auth } from "services/firebaseConfig";
import Light from "components/headers/light";
import LightFuncionaario from "components/headers/lightFuncionaario";
import Footer from "components/footers/Footer";

import { useNavigate,  useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { collection, addDoc, setDoc, doc, query, where, getDocs } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"

const Container = tw.div`relative bg-gray-200`;
const Content = tw.div`max-w-screen-xl mx-auto py-2 lg:py-2`;

const FormContainer = styled.div`
  ${tw`p-10 my-20 sm:p-12 md:p-16  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-2xl font-light`}
  }
  input:not(input[type="radio"]),textarea, select, option {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-b-2 py-2 text-white hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const ColumnFull = tw.div`sm:w-full flex flex-col pl-10`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const LabelRadio = tw.label`pr-3 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const Radio = tw.input`w-auto`;
const Option = tw.option``;
const Select = tw.select``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-64 mt-6 mr-10 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 z-50 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 z-50 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-secondary-500 fill-current w-24`

export default () => {
  const {id} = useParams();
 
    const navigate = useNavigate();
    const [usuarios, setUsuarios] = useState([]);
    const [clicado, setClicado] = useState(false)
    const [curriculos, setCurriculos] = useState([]);
    const curriculosCollectionRef = collection(db, "curriculos");
    const q = query(curriculosCollectionRef, where("useruid", "==", String(id)));
    const [user, loading, error] = useAuthState(auth);
    const [tipo, setTipo] = useState("");

    
    if (error) {
      return (
        <div>
          <p>Error: {error}</p>
        </div>
      );
    }
    if (!user) {
      navigate("../")     
    }
      const usersCollectionRef = collection(db, "users");
      
    useEffect(() => {
  if (user) {
    const q = query(usersCollectionRef, where("uid", "==", String(user.uid)));
        const getUsers = async () => {
            const data = await getDocs(q)
            console.log(data);
            setUsuarios(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getUsers()
      }
    }, [user])
    useEffect(()=> {
      if(usuarios[0]){
        
          
          setTipo(usuarios[0].tipo)       
      }
 
        
    }, [usuarios])





    useEffect(() => {
        const getCurriculos = async () => {
            const data = await getDocs(q)
            console.log(data);
            setCurriculos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getCurriculos()
    }, [])
    useEffect(()=> {
        
        console.log(curriculos)
    }, [curriculos])


  const { register, watch, handleSubmit } = useForm();
  const [cadastrado, setCadastrado] = useState(false);
  const [numCNH, setNumCNH] = useState("")
  const [catCNH, setCatCNH] = useState("")
  const [vencCNH, setVencCNH] = useState("")

  const curriculoCollection = collection(db, "curriculos")
  const usersCollection = collection(db, "users")
  const [parte2, setParte2] = useState(false)
  const onSubmit = async (data) => {

 
    
    await setDoc(doc(curriculoCollection, id), {
      useruid: id,
      nome: data.nome,
      genero: data.genero,
      nascimento: data.nascimento,
      estadoCivil: data.estadoCivil,
      celular: data.celular,
      telefone: data.telefone,
      cpf: data.documento,
      cnh: data.cnh,
      numCNH: data.numCNH,
      catCNH: data.catCNH,
      vencCNH: data.vencCNH,
      atuacao: data.atuacao,
      cursoAtivo: data.cursoAtivo,
      rg: data.rg,
      cnh: data.cnh,
      rua: data.rua,
      complemento: data.complemento,
      cidade: data.cidade,
      numero: data.numero,
      bairro: data.bairro,  
      estado: data.estado,
    }, {merge:true})
    await setDoc(doc(usersCollection, id), {
      curriculo: true,
      nome: data.nome,
      cpf: data.documento,
    }, {merge: true})
    setCadastrado(true);
  }
  return (<>
    {tipo != "funcionario"  &&<Light navigate={navigate} />}
        {tipo == "funcionario"  &&<LightFuncionaario navigate={navigate} />}
    <Container>
      <Content>
      {curriculos.map((curri, index) => (
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer tw="bg-gray-600">
          <div tw="mx-auto max-w-5xl">
          <h2>Dados Pessoais</h2>
            
            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Nome</Label>
                    <Input {...register("nome", {value: curri.nome})} disabled={cadastrado} id="name-input" type="text" name="nome" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado Civil</Label>
                    <Select id="name-input" {...register("estadoCivil")}>
                                     <option value={curri.estadoCivil} disabled selected >{curri.estadoCivil}</option>
                                     <option style={{color: "black"
                                    }} >Solteiro</option>
                                    <option style={{color: "black"
                                    }}>Casado</option>
                                    <option style={{color: "black"
                                    }}>União Estável</option>
                                    <option style={{color: "black"
                                    }}>Separado</option>
                                    <option style={{color: "black"
                                    }}>Viúvo</option>
                                    <option style={{color: "black"
                                    }}>Desquitado</option>
                                    <option style={{color: "black"
                                    }}>Divorciado</option>
                                    <option style={{color: "black"
                                    }}>Outro</option>
                                  </Select>
                    
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">CPF</Label>
                    <Input {...register("documento", {value: curri.cpf})} disabled={cadastrado} id="name-input" type="text" name="documento"  />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Gênero</Label>
                    <Select id="name-input" {...register("genero")}>
                                     <option value={curri.genero} disabled selected >{curri.genero}</option>
                                     <option style={{color: "black"
                                    }} >Homem</option>
                                    <option style={{color: "black"
                                    }}>Mulher</option>
                                    <option style={{color: "black"
                                    }}>Homem Trans</option>
                                    <option style={{color: "black"
                                    }}>Mulher Trans</option>
                                    <option style={{color: "black"
                                    }}>Intergênero</option>
                                    <option style={{color: "black"
                                    }}>Outro</option>
                                  </Select>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Celular</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("celular", {value: curri.celular})} type="text" name="celular"  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">RG</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("rg", {value: curri.rg})} type="text" name="rg"  />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Nascimento</Label>
                    <Input id="name-input" disabled={cadastrado} {...register("nascimento", {value: curri.nascimento})} type="text" name="nascimento"  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Telefone</Label>
                    <Input id="email-input" disabled={cadastrado} type="text" {...register("telefone", {value: curri.telefone})} name="telefone"  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Cargo/Área de Atuação desejado</Label>
                    <Select id="name-input" {...register("atuacao")}>
                                     <option value={curri.atuacao} disabled selected >{curri.atuacao}</option>
                                     <option style={{color: "black" }} >FISCAL </option>
                                      <option style={{color: "black" }} >FINANCEIRO</option>
                                      <option style={{color: "black" }} >SOLDADOR TIG</option>
                                      <option style={{color: "black" }} >SOLDADOR MIG/MAG</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MANUTENÇÃO</option>
                                      <option style={{color: "black" }} >MECÂNICO DE MONTAGEM</option>
                                      <option style={{color: "black" }} >RECURSOS HUMANOS</option>
                                      <option style={{color: "black" }} >SEGURANÇA DO TRABALHO</option>
                                      <option style={{color: "black" }} >ELETRICISTA</option>
                                      <option style={{color: "black" }} >QUALIDADE</option>
                                      <option style={{color: "black" }} >CONTROLADORIA</option>
                                      <option style={{color: "black" }} >ENGENHARIA</option>
                                      <option style={{color: "black" }} >COMPRAS</option>
                                      <option style={{color: "black" }} >ALMOXARIFADO</option>
                                      <option style={{color: "black" }} >LOGÍSTICA</option>
                                      <option style={{color: "black" }} >LIMPEZA</option>
                                      <option style={{color: "black" }} >CONSTRUÇÃO CIVIL</option>
                                      <option style={{color: "black" }} >TRANSPORTE / MOTORISTA</option>
                                      <option style={{color: "black" }} >AJUDANTE</option>
                                      <option style={{color: "black" }} >ESMERILHADOR</option>
                                      <option style={{color: "black" }} >OPERADOR DE EMPILHADEIRA</option>
                                      <option style={{color: "black" }} >OPERADOR DE PONTE ROLANTE</option>
                                      <option style={{color: "black" }} >OPERADOR DE MANDRILHADORA</option>
                                      <option style={{color: "black" }} >PINTOR DE VEÍCULOS</option>
                                      <option style={{color: "black" }} >MAÇARIQUEIRO</option>
                                      <option style={{color: "black" }} >OPERADOR DE MÁQUINAS OPERATRIZES</option>
                                      <option style={{color: "black" }} >DESENHISTA PROJETISTA</option>
                                  </Select>
                  </InputContainer>
                </Column>
                
              </TwoColumn>
              <TwoColumn>
                <Column>
                  <InputContainer>
                  <Label>Possui CNH?</Label>
                  <Select id="name-input" {...register("cnh", {
                    onChange: (e) => {
                      setClicado(true)
                    }
                  })}>
                                     <option value={curri.cnh} disabled selected >{curri.cnh=="true" ? (<>Sim</>):(<>Não</>)}</option>
                                     <option value="true" style={{color: "black" }} >Sim</option>
                                      <option value="false" style={{color: "black" }} >Não</option>
                                      </Select>
                  </InputContainer>
                </Column>
                
              </TwoColumn>
              {(watch("cnh") == "true" || !clicado && curri.cnh == "true") ? (
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Número da CNH</Label>
                    <Input {...register("numCNH",{value: curri.numCNH})} disabled={cadastrado} id="name-input" type="text" name="numCNH"  />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Vencimento da CNH</Label>
                    <Input {...register("vencCNH",{value: curri.vencCNH})} disabled={cadastrado} id="name-input" type="text" name="vencCNH"  />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Categoria da CNH</Label>
                    <Input {...register("catCNH",{value: curri.catCNH})} disabled={cadastrado} id="name-input" type="text" name="catCNH" />
                  </InputContainer>
                </Column>
              </TwoColumn>):(<><Input {...register("numCNH",{value: ""})} disabled={cadastrado}  type="hidden" name="numCNH"  />
              <Input {...register("vencCNH",{value: ""})} disabled={cadastrado} id="name-input" type="hidden" name="vencCNH" />
              <Input {...register("catCNH",{value: ""})} disabled={cadastrado} id="name-input" type="hidden" name="catCNH" /></>)
              }
              <TwoColumn>
                <ColumnFull>
                  <InputContainer>
                  <Label>Possui curso ativo de CBSP  e HUET?</Label>
                    <LabelRadio htmlFor="name-input">
                    <Radio {...register("cursoAtivo")} disabled={cadastrado} id="name-input" checked={curri.cnh == "true"} type="radio" value={true} />Sim</LabelRadio>
                    <LabelRadio htmlFor="name-input">
                    <Radio {...register("cursoAtivo")} disabled={cadastrado} id="name-input" checked={curri.cnh == "false"} type="radio" value={false}  />Não</LabelRadio>
                  </InputContainer>
                </ColumnFull>
                
              </TwoColumn>

              
            
          </div>
          <SvgDotPattern1 />
        </FormContainer>



       <FormContainer tw="bg-gray-600">
          <div tw="mx-auto max-w-5xl">
            <h2>Endereço</h2>
            
              <TwoColumn>
              <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Logradouro</Label>
                    <Input id="name-input" disabled={cadastrado} {...register("rua", {value: curri.rua})} type="text" name="rua"  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Número</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("numero", {value: curri.numero})} type="text" name="numero"  />
                  </InputContainer>
                
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Complemento</Label>
                    <Input id="name-input" disabled={cadastrado} type="text" {...register("complemento", {value: curri.complemento})} name="complemento" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Bairro</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("bairro", {value: curri.bairro})} type="text" name="bairro"  />
                  </InputContainer>
                 
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Cidade</Label>
                    <Input id="name-input" disabled={cadastrado} {...register("cidade", {value: curri.cidade})} type="text" name="cidade"  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado</Label>
                    <Input id="email-input" disabled={cadastrado} {...register("estado", {value: curri.estado})} type="text" name="estado" />
                  </InputContainer>
                  
                </Column>
              </TwoColumn>

              <SubmitButton tw="text-secondary-500" type="submit" disabled={cadastrado} value="Submit">Alterar Currículo</SubmitButton>
              <SubmitButton tw="text-secondary-500" onClick={()=>{navigate("/home")}} type="button" value="Submit">Voltar</SubmitButton>
              {cadastrado && <div>Alterado com sucesso</div>}
          </div>
          <SvgDotPattern2 />
        </FormContainer> 
        </form>
      ))}
      </Content>
    </Container>
    <Footer></Footer>
    </>
  );
};
