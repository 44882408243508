import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { auth } from "services/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import Funcionario from "components/features/funcionario";
import TemCurriculo from "components/features/temCurriculo";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import HeaderFuncionario from "components/headers/lightFuncionaario";
import Footer from "components/footers/Footer.js";
import Vagas from "components/features/Vagas";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import SimpleContactUs from "components/forms/SimpleContactUs";

const Address = tw.span`leading-relaxed`;

const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;


export default (props) => {
  const [usuarios, setUsuarios] = useState([]);
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [temCurriculo, setTemCurriculo] = useState("");
    const [tipo, setTipo] = useState("");
    const [userUid, setUserUid] = useState("");
    
    if (error) {
      return (
        <div>
          <p>Error: {error}</p>
        </div>
      );
    }
    if (!user) {
      navigate("../")     
    }
      const usersCollectionRef = collection(db, "users");
      
    useEffect(() => {
  if (user) {
    const q = query(usersCollectionRef, where("uid", "==", String(user.uid)));
        const getUsers = async () => {
            const data = await getDocs(q)
            console.log(data);
            setUsuarios(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getUsers()
      }
    }, [user])
    useEffect(()=> {
      if(usuarios[0]){
        if(usuarios[0].curriculo){
          setTemCurriculo(true)
        } else {
          setTemCurriculo(false)
        }
          if(usuarios[0].tipo != "funcionario") {
            navigate("home/")
          }  
      }
      console.log(temCurriculo + "  " + tipo)
        
    }, [usuarios])
    

  return (
    <AnimationRevealPage>
      
      <HeaderFuncionario navigate={navigate} />
      
      <Vagas user={user} setCurriculoAtual={props.setCurriculoAtual} />
      
      <Footer />
    </AnimationRevealPage>
  );
};
