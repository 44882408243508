import React, { useState, props } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { collection, addDoc, setDoc, doc, arrayUnion } from "firebase/firestore";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ModalFormacao from "components/features/ModalFormacao";
import { mask } from "remask";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto pb-20 lg:pb-24`;
const Message = tw.span`text-2xl mt-20 `;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 mt-20  text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-2xl sm:text-2xl font-light`}
  }
  input,textarea {
    ${tw`w-full   text-base font-medium tracking-wide border-b-2 py-2  hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;

const Column = tw.div`sm:w-6/12 flex flex-col pl-10`;
const ColumnFull = tw.div`sm:w-full flex flex-col pl-10`;

const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input`text-white bg-transparent`;
const TextArea = tw.textarea`w-full text-gray-700  px-8 h-72 resize-none py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-700 text-sm focus:outline-none focus:border-gray-400 bg-white mt-5 first:mt-0`;
const SubmitButton = tw.button`w-full sm:w-64 mt-6 mr-10 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-secondary-500 fill-current w-24`

export default ({userUid, setCadastroCursos, setCadastrado2}) => {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [modal, setModal] = useState(false)

  const [cadastrado, setCadastrado] = useState(false);
  const cursosCollection = collection(db, "curriculos", userUid, "cursos")
  const usersCollection = collection(db, "curriculos")

  const onSubmit = async (data) => {

    const uid = uuid();
    await setDoc(doc(cursosCollection, uid), {
      cursoUid: uid,
      titulo: data.titulo,
      dataInicio: data.dataInicio,
      dataFinal: data.dataFinal,
      instituicao: data.instituicao,
      descricao: data.descricao
      
    })
    await setDoc(doc(usersCollection, userUid), {
      cursos : arrayUnion(data.titulo),
      instituicoes : arrayUnion(data.instituicao),
      
    }, {merge: true})
    
    setModal(true)
  }
  const mascara = (e, data) => {
    setValue(e.target.name, mask(e.target.value, data))
  }
  return (
    <>
    {modal && <ModalFormacao setModal={setModal} setCadastrado={setCadastrado} palavra="Curso ou Cerficicação" setCadastro={setCadastroCursos} setCadastrado2={setCadastrado2} reset={reset}></ModalFormacao>}

    <Container>
      <Content>
        <form onSubmit={handleSubmit(onSubmit)} tw="pt-20">
        <Message>Prezado, coloque aqui os cursos e certificados que você possui para compor seu currículo:
</Message>

        <FormContainer tw="bg-gray-600">
          <div tw="mx-auto max-w-5xl">
          <h2>Cadastrar Cursos e Certificados</h2>
            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Título</Label>
                    <Input {...register("titulo")} disabled={cadastrado} id="name-input" type="text" name="titulo" placeholder="Título" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Data Final</Label>
                    <Input {...register("dataFinal", {onChange: (e) => {mascara(e, "99/99/9999")}})} disabled={cadastrado} id="name-input" type="text" name="dataFinal" placeholder="Data Final" />
                  </InputContainer>
                  </Column>
                  <Column>
                  <InputContainer>
                    <Label htmlFor="email-input">Instituição</Label>
                    <Input {...register("instituicao")} disabled={cadastrado} id="email-input" type="text" name="instituicao" placeholder="Instituição" />
                  </InputContainer>
                 
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Início</Label>
                    <Input {...register("dataInicio", {onChange: (e) => {mascara(e, "99/99/9999")}})} disabled={cadastrado} id="name-input" type="text" name="dataInicio" placeholder="Data de Início" />
                  </InputContainer>
                 </Column>
               
                
              </TwoColumn><TwoColumn>
                <ColumnFull>
                  <InputContainer>
                    <Label htmlFor="name-input">Descrição</Label>
                    <TextArea id="message-input" disabled={cadastrado} {...register("descricao")} type="text" name="descricao" placeholder="Descrição" />
                  </InputContainer>
                  
                </ColumnFull>
              </TwoColumn>

              
              {!cadastrado && (<>
              <SubmitButton tw="text-gray-900" type="submit"  value="Submit">Cadastrar Curso ou certificação</SubmitButton>
              <SubmitButton tw="text-gray-900" type="button" onClick={()=>{setCadastroCursos(false)}}  value="Submit">Voltar</SubmitButton>
              </>)}
              
              {cadastrado && <>
              <p><br></br>Cadastrado com sucesso!</p>
              <SubmitButton tw="text-gray-900" type="submit" onClick={()=>{setCadastroCursos(false)}}  value="Submit">Ok</SubmitButton>
              </>}
            
          </div>
          <SvgDotPattern1 />
        </FormContainer>



       
          
        </form>
      </Content>
    </Container>
    </>
  );
};
