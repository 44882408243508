import React, { useState, props, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { auth } from "services/firebaseConfig";
import { useNavigate, useParams, Link } from "react-router-dom";
import { collection, query, where, getDocs, arrayRemove, doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import Light from "components/headers/light";
import LightFuncionaario from "components/headers/lightFuncionaario";
import Footer from "components/footers/Footer";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"


const Container = tw.div`relative bg-gray-200`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-10`;
const SpanVoltar = tw.span`text-gray-800 font-bold cursor-pointer`


const FormContainer = styled.div`
  ${tw`p-4 my-10 sm:p-4 md:p-4  text-gray-800  relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-xl pl-10 sm:text-xl font-normal`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-600 text-base font-light tracking-wide  py-2   focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-white`}
    }
  }
`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Radio = tw.input`w-auto`;
const ColumnFull = tw.div`sm:w-full flex flex-col pl-10`;
const LabelRadio = tw.label`pr-3 tracking-wide font-semibold text-sm`;
const Column = tw.div`sm:w-4/12 flex flex-col pl-10`;
const InputContainer = tw.div`relative py-4 mt-2`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full relative top-0 sm:w-32  py-3 bg-gray-600 text-white rounded-full font-light tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-700 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-gray-500 fill-current w-24`
const SvgDotPattern2 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-gray-500 fill-current w-24`

export default ({id, setVerCurriculo, vagaUid}) => {
  const [usuarios, setUsuarios] = useState([]);
    const navigate = useNavigate()
    const [curriculos, setCurriculos] = useState([]);
    const [academica, setAcademica] = useState([]);
    const [experiencias, setExperiencias] = useState([]);
    const curriculosCollectionRef = collection(db, "curriculos");
    const vagasCollectionRef = collection(db, "vagas");

    const academicaCollectionRef = collection(db, "curriculos", id, "academica");
    const experienciasCollectionRef = collection(db, "curriculos", id, "experiencias");
    const [user, loading, error] = useAuthState(auth);
    const [tipo, setTipo] = useState("");

    
    if (error) {
      return (
        <div>
          <p>Error: {error}</p>
        </div>
      );
    }
    if (!user) {
      navigate("../")     
    }
      const usersCollectionRef = collection(db, "users");
      
    useEffect(() => {
  if (user) {
    const q = query(usersCollectionRef, where("uid", "==", String(user.uid)));
        const getUsers = async () => {
            const data = await getDocs(q)
            console.log(data);
            setUsuarios(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getUsers()
      }
    }, [user])
    useEffect(()=> { if(usuarios[0])
        setTipo(usuarios[0].tipo)  
        
        
    }, [usuarios])

    
    useEffect(() => {
      const qCurriculos = query(curriculosCollectionRef, where("useruid", "==", String(id)));
        const getCurriculos = async () => {
            const data = await getDocs(qCurriculos)
            console.log(data);
            setCurriculos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        const qAcademica = query(academicaCollectionRef);
        const getAcademica = async () => {
            const data = await getDocs(qAcademica)
            console.log(data);
            setAcademica(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        const qExperiencias = query(experienciasCollectionRef);
        const getExperiencias = async () => {
            const data = await getDocs(qExperiencias)
            console.log(data);
            setExperiencias(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getCurriculos()
        getAcademica()
        getExperiencias()
    }, [])
    useEffect(()=> {
        const teste = async () => {
            if(curriculos[0]){
         
          await setDoc(doc(usersCollectionRef, curriculos[0].useruid), {
            
            novidade: arrayRemove(vagaUid),
            
      }, {merge:true})   
      }
        }
        const teste2 = async () => {
            if(curriculos[0]){
         
          await setDoc(doc(vagasCollectionRef, vagaUid), {
            
            novidade: arrayRemove(curriculos[0].useruid),
            
      }, {merge:true})   
      }
        }
        
      
 teste()
 teste2()
    }, [curriculos])
    
  
  return (<>

    <Container>
      <Content>
      <SpanVoltar className="spanVoltar" onClick={()=>{setVerCurriculo(false)}}>←Voltar</SpanVoltar>

      {curriculos.map((curri, index) => (
        <form >
         <FormContainer tw="bg-white">
          <div tw="mx-auto ">
          <h2 className="formTitle">Dados Pessoais</h2>
            
            
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Nome</Label>
                    <Input  id="name-input" type="text" name="nome" value={curri.nome} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado Civil</Label>
                    <Input  id="email-input" type="text" name="estadoCivil" value={curri.estadoCivil} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">CPF</Label>
                    <Input id="name-input" type="text" name="documento" value={curri.cpf} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Cargo/Área de Atuação desejado</Label>
                    <Input id="name-input" type="text" name="documento" value={curri.atuacao} disabled  />
                  </InputContainer>
                  
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Gênero</Label>
                    <Input id="name-input"  type="text" name="genero" value={curri.genero} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Celular</Label>
                    <Input id="email-input"  type="text" name="celular" value={curri.celular} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">RG</Label>
                    <Input id="email-input" type="text" name="rg" value={curri.telefone} disabled  />
                  </InputContainer>
                  <InputContainer>
                  <Label>Possui CNH?</Label>
                  {curri.cnh == "true" ? (<Input  disabled id="name-input" type="text" value="Sim" name="nome" />):(<Input  disabled id="name-input" type="text" value="Não" name="nome" />)}
                  
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Data de Nascimento</Label>
                    <Input id="name-input"  type="text" name="nascimento"  value={curri.nascimento} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Telefone</Label>
                    <Input id="email-input" type="text"  name="telefone" value={curri.telefone} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">E-mail</Label>
                    <Input id="email-input" type="text"  name="cnh" value={curri.email} disabled />
                  </InputContainer>
                </Column>
                
              </TwoColumn>
              
              {curri.cnh == "true" && 
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Número da CNH</Label>
                    <Input  disabled id="name-input" type="text" value={curri.numCNH} name="nome" />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Vencimento da CNH</Label>
                    <Input disabled id="name-input" type="text" value={curri.vencCNH}   name="nome"  />
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Categoria da CNH</Label>
                    <Input disabled id="name-input" type="text" value={curri.catCNH}   name="nome"  />
                  </InputContainer>
                </Column>
              </TwoColumn>}
              <TwoColumn>
                <ColumnFull>
                  <InputContainer>
                  <Label>Possui curso ativo de CBSP  e HUET?</Label>
                  {curri.cursoAtivo == "true" ? (<Input  disabled id="name-input" type="text" value="Sim" name="nome" />):(<Input  disabled id="name-input" type="text" value="Não" name="nome" />)}
                  </InputContainer>
                </ColumnFull>
                
              </TwoColumn>
              

              

              
            
          </div>
          
        </FormContainer>



        <FormContainer tw="bg-white">
          <div tw="mx-auto ">
            <h2>Endereço</h2>
            
              <TwoColumn>
              <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Logradouro</Label>
                    <Input id="name-input"  type="text" name="rua" value={curri.rua} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Número</Label>
                    <Input id="email-input"  type="text" name="numero" value={curri.numero} disabled  />
                  </InputContainer>
                
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Complemento</Label>
                    <Input id="name-input" type="text"  name="complemento" value={curri.complemento} disabled  />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Bairro</Label>
                    <Input id="email-input"  type="text" name="bairro" value={curri.bairro} disabled  />
                  </InputContainer>
                 
                </Column>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Cidade</Label>
                    <Input id="name-input"  type="text" name="cidade" value={curri.cidade} disabled />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Estado</Label>
                    <Input id="email-input"  type="text" name="estado" value={curri.estado} disabled />
                  </InputContainer>
                  
                </Column>
              </TwoColumn>

              
            
          </div>
        </FormContainer> 
        </form>
      ))}
      {academica.map((academica, index) => (
         <form>
         <FormContainer tw="bg-white">
           <div tw="mx-auto ">
           <h2>Formação Acadêmica {index + 1}</h2>
             
               <TwoColumn>
                 <Column>
                   <InputContainer>
                     <Label htmlFor="name-input">Curso</Label>
                     <Input value={academica.curso} disabled id="name-input" type="text" name="curso"  />
                   </InputContainer>
                   <InputContainer>
                     <Label htmlFor="name-input">Data de Término</Label>
                     <Input value={academica.termino} disabled id="name-input" type="text" name="termino"  />
                   </InputContainer>
                   </Column>
                   <Column>
                   <InputContainer>
                     <Label htmlFor="email-input">Escola</Label>
                     <Input value={academica.escola} disabled id="email-input" type="text" name="escola"  />
                   </InputContainer>
                   </Column><Column>
                   <InputContainer>
                     <Label htmlFor="name-input">Data de Início</Label>
                     <Input value={academica.inicio} disabled id="name-input" type="text" name="inicio" />
                   </InputContainer>
                 
                   <InputContainer>
                     <Label htmlFor="name-input">Habilidades Adquiridas</Label>
                     <TextArea id="message-input" disabled value={academica.habilidades} type="text" name="habilidades"  />
                   </InputContainer>
                   
                 </Column>
                
                 
               </TwoColumn>
              
           </div>
         </FormContainer>
   
         </form>
      ))}
      {experiencias.map((experiencia, index) => (
          <form >
          <FormContainer tw="bg-white">
            <div tw="mx-auto ">
            <h2>Experiência Profissional {index + 1}</h2>
              
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Nome da Empresa</Label>
                      <Input value={experiencia.empresa} disabled id="name-input" type="text" name="empresa" />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="name-input">Data de Saída</Label>
                      <Input value={experiencia.saida} disabled id="name-input" type="text" name="saida" />
                    </InputContainer>
                    </Column>
                    <Column>
                    <InputContainer>
                      <Label htmlFor="email-input">Cargo</Label>
                      <Input value={experiencia.cargo} disabled id="email-input" type="text" name="cargo" />
                    </InputContainer>
                    </Column><Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Data de Início</Label>
                      <Input value={experiencia.inicio} disabled id="name-input" type="text" name="inicio" />
                    </InputContainer>
                  
                    <InputContainer>
                      <Label htmlFor="name-input">Funções</Label>
                      <TextArea value={experiencia.funcao} id="message-input" disabled type="text" name="funcoes" />
                    </InputContainer>
                    
                  </Column>
                 
                  
                </TwoColumn>
  
               
              
            </div>
          </FormContainer>
  
  
  
         
            
          </form>
          
      ))}
      <Link to={"/curriculoPDF/" + id} target="_blank" rel="noopener noreferrer"><SpanVoltar className="spanVoltar" >+ Download PDF</SpanVoltar></Link>

      </Content>
    </Container>
    
    </>
  );
};
