import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { db } from "services/firebaseConfig";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useNavigate } from "react-router-dom";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { doc, deleteDoc } from "firebase/firestore";


const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ModalWrapper = styled.div`
  ${tw`w-full h-full bg-black fixed top-0 left-0 opacity-75 z-40`}
`;
const Modal = styled.div`
  ${tw`max-w-500 w-full h-48 rounded-2xl   bg-gray-200 fixed inset-1/2 opacity-100 z-50 -translate-x-1/2 -translate-y-1/2 p-10 text-gray-600 text-center`}
  span{
    ${tw`text-black mt-3 leading-6 block`}
  }
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;
const SubmitButton = tw.button`w-full mt-4 text-primary-600 sm:w-32 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;
const SubmitButton2 = tw.button`w-full mt-4 ml-2 text-primary-600 sm:w-32 py-3 bg-gray-100  rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;


const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;


export default ({
    setModal,
    setNewUser,
    setTemCurriculo,
 
}) => {
  const navigate =  useNavigate()
  const [deletado, setDeletado] = useState(false);
  
  const action = ()=>{
    
    setModal(false)
  
  }

    
    
    
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <>
    <ModalWrapper>
        
    </ModalWrapper>
    <Modal><span>Foi enviado um e-mail com as orientações para a recuperação da senha</span>
       
        <SubmitButton2 type="button" onClick={()=>{action()}} >Continuar</SubmitButton2>
        </Modal></>
  );
};
