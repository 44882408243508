import React, { useEffect, useState, props } from "react";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { db } from "services/firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;
const Row = styled.div`
${tw`flex flex-col items-center  lg:items-stretch overflow-hidden lg:flex-row mt-1 bg-primary-300 transition duration-200 hover:bg-primary-800  flex-wrap`}
  div{
    ${tw`p-2 border-r-white text-white border-r-4 `}
  }
  .colunaNome{
    ${tw`lg:w-1/4 pl-5 `}
  }
  .colunaFuncao{
    ${tw`lg:w-1/6 `}
  }
  .colunaIcon{
    ${tw`lg:w-1/12 cursor-pointer `}
  }
  
`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`
const Actions = styled.div`
  ${tw`relative my-10 max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "Currículos",
  heading = <>Gerenciar <span tw="text-primary-500">Currículos</span></>,
  description = "Some amazing blog posts that are written by even more amazing people.",
  curriculoAtual,
  setCurriculoAtual
  

}) => {
  const [busca, setBusca] = useState("");
    const navigate = useNavigate();
    const [curriculos, setCurriculos] = useState([]);
    const curriculosCollectionRef = collection(db, "curriculos")

    useEffect(() => {
      const q = query(curriculosCollectionRef);
        const getCurriculos = async () => {
            const data = await getDocs(q)
            
            setCurriculos(data.docs.map((doc)=> ({...doc.data(), id: doc.id})))
            
        }
        getCurriculos()
    }, []  [busca])
    useEffect(()=> {
        console.log(curriculos)
    }, [curriculos])
  
  return (
    <Container>
     
      <Content>

      <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <Actions>
              <input type="text" placeholder="Digite sua Busca" />
              <button>Filtrar</button>
            </Actions>
          
        </HeadingInfoContainer>
          {curriculos.map((curri, index) => (
            <Row key={index}>
          <div className="colunaNome">{curri.nome}</div>
          <div className="colunaFuncao">Cargo</div>
          <div className="colunaFuncao">{curri.email}</div>
          <div className="colunaFuncao">{curri.cpf}</div>
          <div className="colunaIcon">Apagar</div>
          <div className="colunaIcon" onClick={(e) => {
                    e.preventDefault()
                    setCurriculoAtual(curri.useruid)
                    navigate("/alterarCurriculo")
                  }}>Editar</div>
          <div className="colunaIcon" onClick={(e) => {
                    e.preventDefault()
                    setCurriculoAtual(curri.useruid)
                    navigate("/verCurriculo")
                  }}>Ver</div>
         </Row>
            
          ))}
       
       
      </Content>
      
    </Container>
  );
};
